<template>
  <div class="detail" v-loading="loading">
      <div class="detail-message">
          <div class="detail-message-title">
              <h3>订单编号：{{bookingInfo.code}}</h3>
              <p v-if="bookingInfo.status == 0">待完善</p>
              <p v-if="bookingInfo.status == 1">待审核</p>
              <p v-if="bookingInfo.status == 2">待签约</p>
              <p v-if="bookingInfo.status == 3">可挑战</p>
              <p v-if="bookingInfo.status == 4">延期</p>
              <p v-if="bookingInfo.status == 5">内部审核中</p>
              <p v-if="bookingInfo.status == 6">已签约</p>
              <p v-if="bookingInfo.status == 7">已取消</p>
              <p v-if="bookingInfo.status == 8">已完成</p>
              <p v-if="bookingInfo.status == 9">线下审核中</p>
              <p v-if="bookingInfo.status == 10">驳回</p>
          </div>
          <div class="detail-message-center" @click="goDetail">
              <div class="detail-message-img">
                  <img :src="bookingInfo.thumbnail" alt="">
                  <div>
                      <h2>{{bookingInfo.name}}</h2>
                      <p>{{bookingInfo.startTime}} 至 {{bookingInfo.endTime}}</p>
                      <p>{{bookingInfo.address}}</p>
                      <span class="grand-hall">#{{bookingInfo.venueType}}#</span>
                  </div>
              </div>
              <div class="detail-message-button">
                  <button type="button" v-if="bookingInfo.status == 0" @click="onSubmit">确认提交</button>
                  <!-- <p>活动运营指南</p> -->
              </div>
          </div>
      </div>
      <!-- 活动基本概况 -->
      <div class="reserve-survey">
        <p class="reserve-title">活动基本概况</p>
        <div class="survey-center">
            <div class="survey-list-cen survey-list-left">
                <div class="survey-list">
                    <p><i>*</i>活动名称</p>
                    <el-input v-model="bookingInfo.name" auto-complete="off" placeholder="请输入活动名称" :disabled="true"></el-input>
                </div>
                <div class="survey-list">
                    <p>活动英文名称</p>
                    <el-input v-model="bookingInfo.ename" auto-complete="off" placeholder="请输入活动英文名称" :disabled="true"></el-input>
                </div>
                <div class="survey-list">
                    <p>活动场次</p>
                    <el-input v-model="bookingInfo.num" auto-complete="off" placeholder="请输入活动场次" :disabled="true"></el-input>
                </div>
                <div class="survey-list">
                    <p>预订日期</p>
                    <el-input v-model="active_date" auto-complete="off" placeholder="" :disabled="true"></el-input>
                    <!-- <el-date-picker v-model="active_date" value-format="yyyy.MM.dd HH:mm" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="onDate" size="mini" :picker-options="pickerOptions"></el-date-picker> -->
                </div>
                <div class="survey-list">
                    <p>活动日期</p>
                    <div class="survey-list-icon">
                        <!-- <el-date-picker v-if="bookingInfo.status == 0" v-model="activituyTime" type="datetimerange" value-format="yyyy.MM.dd HH:mm" range-separator="添加"  @change="onDateActiv" size="mini" :picker-options="pickerActive"></el-date-picker> -->
                        <div class="survey-list-date">
                            <div v-for="(item,index) in bookingInfo.activityTimeList" :key="index"><p>{{item.activityStart}} 至 {{item.activityEnd}}</p></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="survey-list-cen survey-list-right">
                <div class="survey-list">
                    <p><i>*</i>三面/四面台</p>
                    <!-- <el-select v-model="bookingInfo.stageType" placeholder="请选择" @change="onSided">
                        <el-option  v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select> -->
                    <el-input v-model="bookingInfo.stageName" auto-complete="off" placeholder="" :disabled="true"></el-input>
                </div>
                <div class="survey-list">
                    <div class="survey-list-radio">
                        <p>售票方式</p>
                        <el-radio-group v-model="bookingInfo.ticketType" @change="onRadio">
                            <el-radio :label="0" disabled size="mini" text-color="#123E90">公开售票</el-radio>
                            <el-radio :label="1" disabled size="mini" text-color="#123E90">非公开售票</el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="survey-list">
                    <p>活动人数</p>
                    <el-input v-model="bookingInfo.viewerNum" auto-complete="off" placeholder="请输入活动人数" :disabled="true"></el-input>
                </div>
                <div class="survey-list">
                    <p>备注</p>
                    <el-input type="textarea" placeholder="请输入其他备注需求限制150字" v-model="bookingInfo.remark" maxlength="150" show-word-limit :disabled="true"></el-input>
                </div>
            </div>
        </div>
      </div>
       <!-- 活动信息 -->
        <div class="reserve-survey reserve-information">
            <p class="reserve-title">活动信息</p>
            <div class="survey-center">
                <div class="survey-list-cen survey-list-left">
                        <div class="survey-list">
                            <p>活动类型</p>
                            <!-- <el-select v-model="activity" v-if="bookingInfo.status == 0" @change='onActivity' value="bookingInfo.type" :placeholder="bookingInfo.type" size="mini" class="m-r-12">
                                <el-option v-for="item in activityTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select> -->
                            <el-input v-model="bookingInfo.type" auto-complete="off" placeholder="请输入" :disabled="true"></el-input>
                        </div>
                        <div class="survey-list" v-for="(item,index) in bookingInfo.list" :key="index">
                            <p>{{item.fieldName}}</p>
                            <el-input v-model="item.value" auto-complete="off" placeholder="请输入" :disabled="true"></el-input>
                        </div>
                </div>
                 <div class="survey-list-cen survey-list-right">
                     <div class="survey-list">
                        <div class="survey-list-radio">
                            <p>是否取消过演出或活动</p>
                            <el-radio-group v-model="bookingInfo.canceled" @change="onCanceled">
                                <el-radio :label="1" disabled size="mini" text-color="#123E90">取消过</el-radio>
                                <el-radio :label="0" disabled size="mini" text-color="#123E90">未取消过</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <div class="survey-list">
                        <p>原因说明</p>
                        <el-input type="textarea" placeholder="请输入" v-model="bookingInfo.cancelReason" maxlength="500" show-word-limit :disabled="true"></el-input>
                    </div>
                    <div class="survey-list">
                        <p>意见/建议</p>
                        <el-input type="textarea" placeholder="请输入" v-model="bookingInfo.suggest" maxlength="200" show-word-limit :disabled="true"></el-input>
                    </div>
                    <div class="survey-list" v-if="url_extension == '.png' || url_extension == '.jpg'">
                        <p>确认函</p>
                        <div class="upload-end">
                            <el-popover placement="top-start" trigger="click">  <!--trigger属性值：hover、click、focus 和 manual-->
                                <a :href="bookingInfo.url" target="_blank" title="查看最大化图片">
                                    <img :src="bookingInfo.url" style="width: auto;height: 600px;">
                                </a>
                                <img slot="reference" :src="bookingInfo.url" class="avater-tips">
                            </el-popover>

                        </div>
                    </div>
                    <div class="survey-list" v-if="url_extension == '.pdf'">
                        <p>确认函</p>
                        <div class="upload-end" style="margin-top:10px;">
                            <a href="" @click="onLetter()"><img src="../../assets/img/pdf.png" alt="" class="enclosure-img" style="width:120px;"/></a>
                        </div>
                    </div>
                 </div>
            </div>
        </div>
        <!-- 主办方信息 -->
        <div class="reserve-survey sponsor">
            <p class="reserve-title">主办方信息</p>
            <div class="survey-center">
                <div class="survey-list-cen survey-list-left">
                    <div class="survey-list">
                        <p>主办方名称</p>
                        <el-input v-model="bookingInfo.companyName" auto-complete="off" placeholder="请输入主办方名称" :disabled="true"></el-input>
                    </div>
                    <div class="survey-list">
                        <p>信用代码</p>
                        <el-input v-model="bookingInfo.creditCode" auto-complete="off" placeholder="请输入信用代码" :disabled="true"></el-input>
                    </div>
                    <div class="survey-list">
                        <p>负责人</p>
                        <el-input v-model="bookingInfo.sponsorName" auto-complete="off" placeholder="请输入负责人" :disabled="true"></el-input>
                    </div>
                    <div class="survey-list">
                        <p>手机号</p>
                        <el-input v-model="bookingInfo.sponsorPhone" auto-complete="off" placeholder="请输入手机号" :disabled="true"></el-input>
                    </div>
                </div>
                <div class="survey-list-cen survey-list-right">
                    <div class="survey-list">
                        <p>邮箱</p>
                        <el-input v-model="bookingInfo.sponsorMail" auto-complete="off" placeholder="请输入邮箱" :disabled="true"></el-input>
                    </div>
                    <div class="survey-list">
                        <p>所在地</p>
                        <div class="sponsor-center">
                            <el-input v-model="bookingInfo.provName" style="width:32%;" :disabled="true"></el-input>
                            <el-input v-model="bookingInfo.cityName" style="width:32%;" :disabled="true"></el-input>
                            <el-input v-model="bookingInfo.districtName" style="width:32%;margin-right:0;" :disabled="true"></el-input>
                        </div>
                        <el-input v-model="bookingInfo.sponsorAddress " auto-complete="off" placeholder="请输入详细地址" :disabled="true"></el-input>
                    </div>
                    <div class="survey-list">
                        <p>营业执照</p>
                        <!-- <div class="upload-end">
                            <img :src="bookingInfo.businessLicense" alt="">
                        </div> -->
                        <el-popover placement="top-start" trigger="click"> <!--trigger属性值：hover、click、focus 和 manual-->
                            <a :href="bookingInfo.businessLicense" target="_blank" title="查看最大化图片">
                                <img :src="bookingInfo.businessLicense" style="width: auto;height: auto;">
                            </a>
                            <img slot="reference" :src="bookingInfo.businessLicense" class="upload-end">
                        </el-popover>
                    </div>
                </div>
            </div>
        </div>
        <!-- 承办方信息 -->
        <div class="reserve-survey undertaker">
            <p class="reserve-title">承办方信息</p>
            <div class="survey-center">
                <div class="survey-list-cen survey-list-left">
                    <div class="survey-list">
                        <p>承办方名称</p>
                        <el-input v-model="bookingInfo.undertakerName" auto-complete="off" placeholder="请输入承办方名称" :disabled="true"></el-input>
                    </div>
                </div>
                <div class="survey-list-cen survey-list-right">
                    <div class="survey-list">
                        <p>所在地</p>
                        <div class="sponsor-center" v-if="bookingInfo.status == 0">
                            <el-select v-model="bookingInfo.undertakerProvName" placeholder="请选择省" value-key="bookingInfo.undertakerProvName" @change="onRegion">
                                <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                            </el-select>
                            <el-select v-model="bookingInfo.undertakerCityName" placeholder="请选择市" value-key="bookingInfo.undertakerCityName" @change="onCity">
                                <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                            <el-select v-model="bookingInfo.undertakerDistrictName" placeholder="请选择区" value-key="bookingInfo.undertakerDistrictName" style="margin-right:0;" @change="onDistrict">
                                <el-option v-for="item in districtList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </div>
                        <div class="sponsor-center" v-else>
                            <el-input v-model="bookingInfo.undertakerProvName" style="width:32%;" :disabled="true"></el-input>
                            <el-input v-model="bookingInfo.undertakerCityName" style="width:32%;" :disabled="true"></el-input>
                            <el-input v-model="bookingInfo.undertakerDistrictName" style="width:32%;margin-right:0;" :disabled="true"></el-input>
                        </div>
                        <el-input v-model="bookingInfo.undertakerAddress" auto-complete="off" placeholder="请输入详细地址" :disabled="true"></el-input>
                    </div>
                </div>
            </div>
        </div>
        <!-- 上传合同 -->
        <div class="reserve-survey enclosure" v-if="bookingInfo.status == 1 || bookingInfo.status == 2 || bookingInfo.status == 6 || bookingInfo.status == 7 || bookingInfo.status == 8">
            <div class="enclosure-top">
                <p class="reserve-title">上传合同</p>
                <div class="enclosure-upload upload-grey" v-if="bookingInfo.status == 1">
                    <i class="el-icon-upload"></i>
                    <p>添加合同</p>
                </div>
                <div class="enclosure-upload" @click="dialogVisible_contract" v-if="bookingInfo.status == 2">
                    <i class="el-icon-upload"></i>
                    <p>添加合同</p>
                </div>
            </div>
            <el-table :data="annexList" height="300" border style="width: 100%">
                <el-table-column prop="img" label="合同" min-width="100" align="center">
                    <template slot-scope="scope">
                        <a href="" v-if="scope.row.extension == '.pdf'" @click="wordDetail(scope.row)"><img src="../../assets/img/pdf.png" alt="" class="enclosure-img"/></a>
                        <a href="scope.row.url" v-else><img :src="scope.row.url" alt="" class="enclosure-img"/></a>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="合同名称" width="250" align="center"></el-table-column>
                <el-table-column prop="amount" label="金额( 单位:元)" align="center"  width="150"></el-table-column>
                <el-table-column prop="uploadTime" label="日期"  align="center" width="180"></el-table-column>
                <el-table-column prop="" label="操作" width="180" align="center" v-if="bookingInfo.status == 2">
                    <template slot-scope="scope">
                        <p @click="deleteOut(scope.row)" class="delete">删除</p>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 记录信息 -->
        <div class="reserve-survey record">
            <p class="reserve-title">记录信息</p>
            <el-table :data="tableData" height="193" border style="width: 100%">
                <el-table-column prop="createTime" label="时间" width="180" align="center"></el-table-column>
                <el-table-column prop="auditResult" label="事件" width="180" align="center"></el-table-column>
                <el-table-column prop="remark" label="备注" align="center"></el-table-column>
            </el-table>
        </div>

        <!-- 添加合同 -->
        <el-dialog title="添加合同" :visible.sync="dialogVisible" width="50%">
            <div>
                <div class="survey-list">
                    <p style="margin-bottom:8px;">合同名称</p>
                    <el-input v-model="visible_name" placeholder="请输入合同名称" @input="onAmountName"></el-input>
                </div>
                <div class="survey-list" style="margin-top:10px;">
                    <p style="margin-bottom:8px;">合同金额</p>
                    <el-input v-model="visible_amount" placeholder="请输入合同金额" @input="onAmount"></el-input>
                </div>
                <p style="margin:10px 0;">请上传pdf格式的合同</p>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <div style="margin-left:10px;">
                        <form id="form">
                            <input type="file" @change="upload" id="upload" />
                        </form>
                        <el-button type="primary" @click="clickShow" v-loading.fullscreen.lock="fullscreenLoading">上传</el-button>
                    </div>
                </span>
            </div>
        </el-dialog>

        <!-- 评价 -->
        <el-dialog title="请您对五棵松场馆进行评价" :visible.sync="assess"  width="50%" v-if="activeStatus == 8">
            <div class="assess-center">
                <div style="margin-bottom:20px;">
                    <p>总体评价</p>
                    <el-input id="input1" type="textarea" :rows="300" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入内容" v-model="assessList.evaluate" class="assess-textrea" style="height:150px !important;min-height:150px !important;"></el-input>
                </div>
                <div>
                    <p>问题与建议</p>
                    <el-input id="input1" type="textarea" :rows="300" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入内容" v-model="assessList.suggest" class="assess-textrea" style="height:150px !important;min-height:150px !important;"></el-input>
                </div>
                <div class="assess-checked">
                    <el-checkbox v-model="assessList.checked">匿名提交</el-checkbox>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onAssess">确认提交</el-button>
            </span>
        </el-dialog>
  </div>
</template>

<script>
import { uploadConfirmation } from "../../api/upload"
import { listArea } from "../../api/address"
import { bookingInfoById,listAnnexByCode,listRecordByCode,saveSuggest,removeConfirmation } from "../../api/personal"
import { listActivityType,saveBooking,listFieldByTypeId,getSponsor } from "../../api/venue"
export default {
    data(){
        return{
            activity:{
                name:"",
                ename:"",
                num:"",
                date:"",
                ticketNum:"",
                viewerNum:"",
                remark:""
            },
            options: [{
                value: 0,
                label: '三面台'
                }, {
                value: 1,
                label: '四面台'
            },{
                value: 2,
                label: '其他'
            }],
            value: '',
            radio: '1',
            activity:"",
            activityTypeList:[],
            canceled:"1",
            cancelReason:"",
            suggest:"",
            imageUrl:"",
            address:"",
            undertakerName:"",
            annexList:[],
            tableData: [],
            activituyTime:"",
            id:"",
            bookingInfo:{},
            active_date:"",
            fieldList:[],
            dialogVisible: false,
            upType:1,
            code:"",
            visible_amount:"",
            url:"",
            areaList:[],
            cityList:[],
            districtList:[],
            pid:100000,
            level: 1,
            fullscreenLoading: false,
            visible_name:"",
            loading: false,
            venueId:"",
            url_extension:"",
            //预订日期 禁选当前日以前时间
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            //活动日期 禁选当前日以前时间
            pickerActive:{
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            activeStatus:"",
            assess:true,
            assessList:{
                evaluate:"",
                suggest:"",
                checked:true
            }
        }
    },
    created(){
        this.id = this.$route.query.id
        console.log('从上一页获取的id',this.id)
        this.activeStatus = this.$route.query.activeStatus
        this.bookingInfoById()
        this.level = 1
        if (this.bookingInfo.undertakerProvId) this.pid = this.bookingInfo.undertakerProvId
        this.listArea(true)
    },
    methods:{
        //评价
        onAssess(){
            if(this.assessList.evaluate == ""){
                this.$message.error("请输入总体评价")
                return false
            }else if(this.assessList.suggest == ""){
                this.$message.error("请输入问题与建议")
                return false
            }
            this.saveSuggest()
            this.assess = false
        },

        saveSuggest(){
            this.loading = true
            saveSuggest({
                code:this.code,
                assess:this.assessList.evaluate,
                suggest:this.assessList.suggest
            }).then((res) => {
                if(res.data.code == 20000){
                    this.loading = false
                    this.$message.success(res.data.message)
                    this.$router.push("/schedule")
                }
            })
        },

        goDetail(){
            console.log(this.venueId)
            this.$router.push({
                name: "detail", 
                params: {
                    id:this.venueId
                }
            });
        },

        //活动类型下拉框
        listActivityType(){
            listActivityType({
                type:this.type
            }).then((res) => {
                if(res.data.code == 20000){
                    this.activityTypeList = res.data.data.activityTypeList
                }
            })
        },

        //删除合同
        deleteOut(row){
            console.log(row)
            this.$confirm("确定删除该合同吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            })
            .then(() => {
                removeConfirmation({
                    id:row.id,
                    code:this.code
                }).then((res) => {
                    if (res.data.code == 20000) {
                        this.$message.success(res.data.message);
                        this.listAnnexByCode(this.code)
                    }
                });
            })
            .catch(() => {
                console.log("取消此操作");
            });
        },

        //打开pdf
        wordDetail(row){
            // console.log(row.url)
            window.open(row.url)
        },

        onLetter(){
            window.open(this.bookingInfo.url)
        },

        onAmount(value){
            this.visible_amount = value
        },

        onAmountName(value){
            this.visible_name = value
        },

        //根据id获取预约详情
        bookingInfoById(){
            this.loading = true
            bookingInfoById({
                id:this.id
            }).then((res) => {
                if(res.data.code == 20000){
                    this.loading = false
                    this.bookingInfo = res.data.data.bookingInfo
                    this.active_date = res.data.data.bookingInfo.startTime + '至' + res.data.data.bookingInfo.endTime 
                    this.code = res.data.data.bookingInfo.code
                    this.url = res.data.data.bookingInfo.url
                    this.venueId = res.data.data.bookingInfo.venueId
                    this.listAnnexByCode(this.code)
                    this.listRecordByCode(this.code)
                    var seat = this.url.lastIndexOf(".");
                    var extension = this.url.substring(seat).toLowerCase() 
                    this.url_extension = extension
                    // console.log(this.url_extension)
                }
            })
        },

        //根据预订代码查询附件列表
        listAnnexByCode(code){
            listAnnexByCode({
                code:code
            }).then((res) =>{
                if(res.data.code == 20000){
                    this.annexList = res.data.data.customerAnnex
                    this.annexList.map(element => {
                        var seat = element.url.lastIndexOf(".");
                        var extension = element.url.substring(seat).toLowerCase() 
                        element.extension = extension
                        return element 
                    });
                }
            })
        },

        //获取信息列表
        listRecordByCode(code){
            listRecordByCode({
                code:code
            }).then((res) => {
                this.tableData = res.data.data.recordList
            })
        },

        //根据类型id获取字段
        listFieldByTypeId(){
            listFieldByTypeId({
                id:this.activity
            }).then((res) => {
                if(res.data.code == 20000){
                    this.fieldList = res.data.data.fieldList.map(item => {
                        item.value = ""
                        return item
                    })
                }
            })
        },

        //是否取消过
        onCanceled(value){
            console.log(value)
        },

        //日期
        onDate(){
            // console.log(this.activity.date)
            this.startdate = this.activity.date[0]
            this.enddate = this.activity.date[1]
        },

        onDelete(index){
            this.bookingInfo.activityTimeList.splice(index,1);
        },

        // 省
        onRegion(value){
            this.bookingInfo.undertakerProv = value
            this.level = 2
            this.pid = value
            this.listArea()
        },

        // 市
        onCity(value){
            this.bookingInfo.undertakerCity = value
            this.level = 3
            this.pid = value
            this.listArea()
        },

        // 区
        onDistrict(value){
            this.bookingInfo.undertakerDistrict = value
        },

        //地区
        listArea(type){
            listArea({
                pid:this.pid
            }).then((res) => {
                if(res.data.code == 20000){
                    if (this.level == 1) {
                        this.areaList = res.data.data.areaList
                        if (type) {
                            this.level = 2
                            this.pid = this.bookingInfo.undertakerProv ? this.bookingInfo.undertakerProv : res.data.data.areaList[0].id
                            this.listArea(type)
                        }
                    } else if (this.level == 2) {
                        this.cityList = res.data.data.areaList
                        if (type) {
                            this.level = 3
                            this.pid = this.bookingInfo.undertakerCity ? this.bookingInfo.undertakerCity : res.data.data.areaList[0].id
                            this.listArea(type)
                        }
                    } else if (this.level == 3) {
                        this.districtList = res.data.data.areaList
                    }
                }
            })
        },


        //添加附件
        clickShow() {
            document.getElementById("upload").click();
        },

        upload() {

            if(this.visible_amount == ""){
                this.$message.error('合同金额不能为空')
                return false
            }else if(this.visible_name == ""){
                 this.$message.error('合同名称不能为空')
                return false
            }

            var file = document.getElementById("upload").files;
            var files = document.getElementById("upload").files[0];

            // console.log(files);
            // console.log(files.size);

            var seat = files.name.lastIndexOf(".");
            //返回位于String对象中指定位置的子字符串并转换为小写.
            var extension = files.name.substring(seat).toLowerCase();
            //判断允许上传的文件格式
            if(extension != ".pdf"){
                this.$message.error("不支持" + extension + "文件的上传!");
                return
            }

            var formDatas = new FormData();
            if (file.length > 0) {
                for (let i = 0; i < file.length; i++) {
                    formDatas.append("file", file[i]);
                    formDatas.append('code', this.code)
                    formDatas.append('type', 1)
                    formDatas.append('name', this.visible_name)
                    formDatas.append('amount', this.visible_amount)
                }
                this.fullscreenLoading = true
                uploadConfirmation(formDatas).then((res) => {
                    if(res.data.code == 20000){
                        this.$message.success(res.data.message)
                        this.dialogVisible = false
                        this.fullscreenLoading = false
                        this.bookingInfoById()
                        // this.$router.push("/schedule")
                    }else{
                        this.$message.error(res.data.message)

                        this.fullscreenLoading = false

                    }
                })
            }
        },

        dialogVisible_contract(){
            this.dialogVisible = true
            this.visible_name = ""
            this.visible_amount = ""
        },

        //选择台
        onSided(value){
            console.log(value)
        },

        //售票方式
        onRadio(value){
            this.bookingInfo.ticketType = value
        }, 

        onDateActiv(){
            this.bookingInfo.activityTimeList.push({activityStart:this.activituyTime[0],activityEnd:this.activituyTime[1]})
            // console.log(this.bookingInfo.activityTimeList)
        },

        //活动类型
        onActivity(value){
            this.activity = value
        },

        // 上传确认函
        async submitUpload({ file }) {
            this.loading = true
            const isLt2M = file.size / 1024 / 1024 < (this.maxSize || 1)
            let formData = new FormData()
            formData.append('file', file, file.name)
            formData.append('code', this.code)
            formData.append('type',0)
            this.fullscreenLoading = true
            let res = await uploadConfirmation(formData)
            this.upload_url = res.data.data.url
            if (res.data.code == 20000) {
                this.loading = false
                this.$emit('selectImg', {url:res.data.data.url })
                this.imageUrl = res.data.data.url
                // this.$router.push("/schedule")
                this.bookingInfoById()
            }
        },

        //确认提交
        onSubmit(){
            if(this.bookingInfo.stageType == "三面台"){
                this.bookingInfo.stageType == 0
            }else if(this.bookingInfo.stageType == "四面台"){
                this.bookingInfo.stageType == 1
            }

            
            saveBooking(this.bookingInfo).then((res) => {
                if(res.data.code == 20000){
                    this.$router.push("/schedule")
                    this.$message.success(res.data.message)
                }else{
                    this.$message.error(res.data.message)
                }
            })
        }

        
    }
}
</script>

<style lang="scss" scoped>
.detail{
    width:100%;
    .reserve-title{
        color:#333333;
        font-size: 16px;
        font-weight:bold;
        margin-bottom:15px;
    }
    .detail-message{
        width:100%;
        height:192px;
        background-color:#ffffff;
        .detail-message-title{
            width:100%;
            height:50px;
            padding:0 30px;
            border-bottom:1px solid #EEEEEE;
            display:flex;
            align-items:center;
            justify-content: space-between;
            h3{
                color:#666666;
                font-size:14px;
            }
            p{
                color:#FF7100;
                font-size:14px;
                font-weight:bold;
            }
        }
        .detail-message-center{
            width:100%;
            height:140px;
            padding:0 30px;
            display:flex;
            align-items:center;
            justify-content: space-between;
            .detail-message-img{
                display:flex;
                img{
                    width:155px;
                    height:100px;
                    border-radius:4px;
                    margin-right:10px;
                }
                div{
                    height:100px;
                    display:flex;
                    flex-direction: column;
                    justify-content: space-between;
                    h2{
                        color:#333333;
                        font-size:18px;
                    }
                    p{
                        color:#989898;
                        font-size:12px;
                    }
                }
            }
            .detail-message-button{
                height:100px;
                display:flex;
                flex-direction: column;
                justify-content: space-between;
                button{
                    width:92px;
                    height: 36px;
                    background: linear-gradient(270deg, #FF6100 0%, #FFB800 100%);
                    border-radius:6px;
                    display:flex;
                    align-items:center;
                    justify-content: center;
                    border:none;
                    color:#FFFFFF;
                    font-size:14px;
                }
                p{
                    color:#123E90;
                    font-size:14px;
                }
            }
        }
    }
    // 活动基本概况
    .reserve-survey{
        width:100%;
        background-color:#FFFFFF;
        margin-top:15px;
        padding:20px;
        .survey-center{
            display:flex;
            justify-content: space-between;
            .survey-list-cen{
                width:45%;
                .survey-list{
                    width:100%;
                    margin-bottom:15px;
                    p{
                        color:#333333;
                        font-size:12px;
                        padding-bottom:6px;
                        i{
                            color:#FF7100;
                            font-size: 12px;
                        }
                    }
                    /deep/ .el-input__inner{
                        height:32px;
                        line-height:23px;
                        width:100%;
                    }
                    .survey-list-date{
                        display:flex;
                        align-items:center;
                        margin-top:10px;
                        flex-wrap: wrap;
                        max-height: 100px;
                        overflow: auto;
                        div{
                            padding:0 12px;
                            height:24px;
                            background-color:#F2F2F2;
                            border-radius:16px;
                            line-height:24px;
                            margin-right:15px;
                            position:relative;
                            margin-bottom:8px;
                            margin-top:3px;
                            p{
                                color:#666666;
                                font-size:14px;
                            }
                            i{
                                width:12px;
                                height: 12px;
                                background-color:#FA6400;
                                border-radius:50%;
                                color:#FFFFFF;
                                font-size:12px;
                                display:flex;
                                align-items:center;
                                justify-content: center;
                                position:absolute;
                                right:-2px;
                                top:-3px;
                            }
                            button{
                                border:none;
                                color:#666666;
                                font-size:14px;
                            }
                        }
                    }
                    /deep/ .el-range-separator{
                        line-height: 20px;
                        width:100%;
                    }
                    .survey-list-radio{
                        display:flex;
                        align-items:center;
                        margin-bottom:10px;
                        p{
                            margin-right:10px;
                            padding-bottom:0;
                        }
                    }
                    .upload-end{
                        display:flex;
                        align-items:flex-end;
                        cursor: pointer;
                        width:140px;
                        height: 96px;
                        .avatar-uploader{
                            width: 140px;
                            height: 96px;
                            background-color: #f2f2f2;
                            border-radius: 4px;
                            border: 1px dashed #D8D8D8;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .avater-tips{
                                display:flex;
                                flex-direction: column;
                                align-items:center;
                                width: 140px;
                                height: 96px;   
                                p{
                                    color:#999999;
                                    font-size:12px;
                                    padding-top:8px;
                                }
                                img{
                                    width:15px;
                                    height:15px;
                                    border-radius:0;
                                }
                            }
                        }
                        .upload-end-submit{
                            margin-left:10px;
                            color:#333333;
                            font-size:12px;
                            span{
                                color:#D8D8D8;
                                font-size:12px;
                            }
                        }
                        img{
                            width: 140px;
                            height: 96px;
                            border-radius:4px;
                        }
                    }
                    .sponsor-center{
                        display:flex;
                        align-items:center;
                        justify-content: space-between;
                        margin-bottom:15px;
                        /deep/ .el-input{
                            width:98%;
                            margin-right:10px;
                        }
                        /deep/ .el-select{
                            margin-right:8px;
                        }
                        /deep/ .el-input__icon{
                            line-height:25px;
                        }
                    }
                    .survey-list-icon{
                        /deep/ .el-date-editor .el-range__icon{
                            display:none;
                        }
                        /deep/ .el-range-editor .el-range-input{
                            display:none;
                        }
                        /deep/ .el-input__inner{
                            height: 24px;
                            background-color: #F2F2F2;
                            border-radius: 12px;
                            color: #666666;
                            font-size: 14px;
                            border: none;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                        }
                    }
                    /deep/ .el-select__caret{
                        line-height: 20px;
                    }
                    /deep/ .el-upload-dragger{
                        width:140px;
                        height:96px;
                        display: flex;
                        align-items:center;
                        justify-content: center;
                    }
                    /deep/ .el-date-editor .el-range-input{
                        width:100%;
                    }
                }
            }
            /deep/ .el-select{
                width:100%;
            }
        }
    }
    //主办方信息
    .sponsor{
        height:378px;
    }
    //承办方信息
    .undertaker{
        height:182px;
    }
    //活动信息
    .reserve-information{
        width: 100%;
        height:478px;
        background-color:#ffffff;
        margin-top:15px;
        padding:20px;
    }
    .reserve-bottom{
        width:100%;
        height:36px;
        margin:50px 0 100px 0;
        display:flex;
        align-items:center;
        justify-content: center;
        button{
            width:142px;
            height:36px;
            background: linear-gradient(270deg, #FF6100 0%, #FFB800 100%);
            border-radius:6px;
            border:none;
            color:#FFFFFF;
            font-size:14px;
        }
    }
    //记录信息
    .record{
        height:282px;
        margin-bottom: 100px;
    }
    //上传合同
    .enclosure{
        height:392px;
        .enclosure-top{
            display:flex;
            align-items:center;
            justify-content: space-between;
            .enclosure-upload{
                display:flex;
                align-items:center;
                color:#123E90;
                cursor: pointer;
                p{
                    color:#123E90;
                    font-size:14px;
                    margin-left:8px;
                }
            }
            .upload-grey{
                display:flex;
                align-items:center;
                color:#999999;
                p{
                    color:#999999;
                    font-size:14px;
                    margin-left:8px;
                }
            }
        }
        .enclosure-img{
            width:40px;
            height:47px;
            border-radius: 4px;
        }
    }
    /deep/ .el-table--border th, .el-table__fixed-right-patch{
        background-color:#D8D8D8;
        color:#333333;
    }
    /deep/ .dialog-footer{
        display:flex;
        align-items: center;
        justify-content:flex-end;
    }
    .avater-tips{
        display:flex;
        flex-direction: column;
        align-items:center;
        p{
            color:#999999;
            font-size:12px;
            padding-top:8px;
        }
        img{
            width:15px !important;
            height:15px !important;
            border-radius:0 !important;
        }
    }
    .pic {
        display: flex;
        justify-content: center;
        margin: 10px 0;
    }
    .form {
        display: flex;
        margin-bottom: 10px;
    }
    #form {
        width: 100px;
        display: none;
    }
    #imgpic {
        width: 50px;
        height: 50px;
        vertical-align: middle;
    }
    .imgs {
        > div {
            width: 100%;
            height: 50px;
            line-height: 50px;
            margin-top: -3px;
            margin-left: 25px;
            img {
                height: 50px;
            }
            span:nth-of-type(1) {
                font-size: 14px;
            }
            span:nth-of-type(2) {
                color: #26a2ff;
                font-weight: bold;
                font-size: 20px;
                cursor: pointer;
            }
        }
    }
    //评价
    .assess-center{
        div{
            p{
                color:#333333;
                font-size:16px;
                margin-bottom:10px;
                font-weight:bold;
            }
        }
        .assess-checked{
            margin-top:12px;
        }
        /deep/ .el-button--primary{
            background: linear-gradient(270deg, #FF6100 0%, #FFB800 100%);
        }
    }
    /deep/ .el-dialog__header{
        background-color:#123E90;
        display:flex;
        align-items:center;
        justify-content: center;
    }
    /deep/ .el-dialog__title{
        color:#FFFFFF;
        font-size:22px;
        font-weight:bold;
    }
    /deep/ .el-dialog__headerbtn .el-dialog__close{
        color:#ffffff;
    }
    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
        background-color: #123E90;
        border-color: #123E90;
    }
    /deep/ .el-checkbox__input.is-checked+.el-checkbox__label{
        color:#123E90;
    }
    /deep/ .el-button--primary{
        background: linear-gradient(270deg, #FF6100 0%, #FFB800 100%);
        border:none;
    }
    // 禁止用户拉伸
    /deep/ .el-textarea .el-textarea__inner{ 
        resize: none;
    }
    .delete{
        cursor: pointer;
    }
    /deep/ .assess-textrea{
        height:150px !important;
        min-height: 150px !important;
        /deep/ .el-textarea{
            height:150px !important;
            min-height: 150px !important;
        }
        /deep/ .el-textarea__inner{
            height:150px !important;
            min-height:150px !important;
            border:1px solid red;
        }
        #input1{
            height:150px !important;
            min-height:150px !important;
        }
    }
}
</style>